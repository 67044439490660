<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import countTo from "vue-count-to";
import Monitor from "@/services/Monitor";

/**
 * Starter page
 */
export default {
    components: { Layout, PageHeader, countTo },
    page: {
        title: "Queues Status",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    async created() {
        this.getQueuesStats()
    },
    methods: {
        getQueuesStats(){
            Monitor.getQueuesStats()
            .then(response => {
                //console.log(response.data)
                this.queuesData = response.data.data;
            })
            .catch(error => {
                //console.log( error.response.data.error)
                this.error = error.response.data.error ? error.response.data.error : "";
            })
        },
    },
    data() {
        return {
            title: "Queues Status",
            items: [
                // {
                //     text: "Queues",
                //     href: "/notifications",
                // },
            ],
            queuesData: {

            },
            error: ''
        };
    },
    mounted: function () {
        this.timer = setInterval(() => {
            this.getQueuesStats()
        }, 60000) //each minute check
    },
    middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-md-6 col-xl-4" v-for="(item, index) in queuesData" :key="item.id">
                <div class="card">
                    <div class="card-header">
                        <h5>Queue version: {{index}}</h5>
                    </div>
                    <div class="card-body">
                        <div class="float-end mt-4">
                            <i v-if="item.status=='active'" class="mdi mdi-48px mdi-check-bold me-2 text-success"></i>
                            <i v-else class="mdi mdi-48px mdi-alarm-light-outline me-2 text-danger animate-flicker"></i>
                        </div>
                        <div>
                            <h1 class="mb-1 mt-1 text-secondary">
                                <span data-plugin="counterup" :class="{'text-warning': item.cntQueues>0, 'text-success': item.cntQueues<1,}">
                                    <countTo :startVal="0" :endVal="item.cntQueues" :duration="10"></countTo>
                                </span>
                            </h1>
                            <p class="mb-0 text-secondary" style="font-size: large;">Jobs in Queue</p>
                        </div>
                        <p class="text-muted mt-3 mb-0" style="font-size: large;">
                            <span class="text-success me-3">
                                <i class="mdi mdi-24px mdi-check-outline me-2"></i> 
                                <b>
                                    <span data-plugin="counterup ">
                                        <countTo :startVal="0" :endVal="item.cntSuccess" :duration="10"></countTo>
                                    </span>
                                </b>
                            </span>
                            <span class="text-danger me-3">
                                <i class="mdi mdi-24px mdi-close-outline me-2"></i>  
                                <b>
                                    <span data-plugin="counterup ">
                                        <countTo :startVal="0" :endVal="item.cntFailed" :duration="10"></countTo>
                                    </span>
                                </b>
                            </span>
                            <span class="text-danger">
                                <router-link :to="{ path: `/monitor/queues/${index}`}">
                                    Details
                                </router-link>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>



  </Layout>
</template>
